<template>
  <v-dialog
    v-model="show"
    :fullscreen="isPhone"
    max-width="900"
    persistent
    overlay-color="#1f2c4c"
    overlay-opacity="70"
    @keydown.esc="cancel"
  >
    <v-card flat class="noscroll-x">
      <v-sheet color="lightHeader" class="silver--text">
        <v-card-title class="title">
          Add Product Variation
          <v-spacer />
          <v-icon color="silver" large @click="cancel" title="Close">
            mdi-close
          </v-icon>
        </v-card-title>
      </v-sheet>
      <template v-if="product">
        <v-card-title class="pl-4 silver--text py-1 body-1">
          Add a product variation for {{ product.name }}
        </v-card-title>
      </template>
      <v-card-actions class="mb-2 accent--text">
        <v-divider />
        <span class="mx-2">Variation Information</span>
        <v-divider />
      </v-card-actions>
      <v-row dense class="px-2">
        <v-col cols="12" md="6">
          <v-text-field
            :error-messages="skuErrors"
            v-model="variation.sku"
            hint="Unique Product Variation Code"
            ref="sku"
            :counter="25"
            label="SKU"
            prepend-icon="mdi-account"
            @blur="$v.variation.sku.$touch()"
          />
        </v-col>
        <v-col cols="12">
          <v-textarea
            :error-messages="descriptionErrors"
            v-model="variation.description"
            ref="description"
            :counter="400"
            :rows="3"
            label="Description"
            prepend-icon="mdi-text"
            @blur="$v.variation.description.$touch()"
          />
        </v-col>
      </v-row>
      <v-row dense class="px-2">
        <v-col cols="12" md="6">
          <v-text-field
            v-model="variation.value"
            ref="value"
            :counter="100"
            label="Value"
            prepend-icon="mdi-text-recognition"
            hint="Specific type differentiator for this product, such as color, weight, size. Can be empty."
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="variation.value_details"
            ref="valueDetails"
            :counter="100"
            label="Value Details"
            prepend-icon="mdi-text-recognition"
            hint="Value details, for example red, white, black, etc. for color. Can be empty."
          />
        </v-col>
        <v-col :cols="variation.use_stock == 'Yes' ? 4 : 12">
          <v-checkbox
            :color="variation.use_stock ? 'silver' : 'accent'"
            class="enlarged-checkbox "
            true-value="Yes"
            false-value="No"
            v-model="variation.use_stock"
            label="Use Stock"
          />
        </v-col>
        <template v-if="variation.use_stock == 'Yes'">
          <v-col cols="4">
            <v-text-field
              v-model="variation.stock"
              ref="stock"
              :counter="5"
              type="number"
              label="Stock"
              prepend-icon="mdi-link-box-outline"
              hint="Initial Stock"
            />
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="variation.limit_qty"
              ref="limitqty"
              :counter="5"
              label="Limit Quantity"
              type="number"
              prepend-icon="mdi-link-box-outline"
              hint="Buy max #"
            />
          </v-col>
        </template>
      </v-row>
      <v-card-actions class="mb-2 accent--text">
        <v-divider />
        <span class="mx-2">Price Information</span>
        <v-divider />
      </v-card-actions>
      <v-row dense class="px-2">
        <v-col cols="12" md="6">
          <v-text-field
            v-model="variation.price"
            ref="price"
            :counter="10"
            label="Price"
            type="number"
            prepend-icon="mdi-cash"
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-select
            v-model="variation.currency"
            ref="currency"
            chips
            prepend-icon="mdi-currency-usd"
            :items="currencies"
            label="Currency"
            outlined
            item-text="name"
            item-value="value"
          />
        </v-col>
        <v-col cols="12">
          <v-select
            v-model="variation.recurrence"
            ref="recurrence"
            chips
            prepend-icon="mdi-autorenew"
            :items="productRecurrence"
            label="Product Recurrence"
            outlined
            item-text="name"
            item-value="value"
            @change="changeRecurrence"
          />
        </v-col>
        <template v-if="this.variation.recurrence != 'none'">
          <v-col cols="6">
            <v-text-field
              :key="switchNum"
              v-model="variation.interval"
              ref="interval"
              :counter="3"
              label="Interval"
              type="number"
              prepend-icon="mdi-clock-start"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="variation.grace"
              ref="grace"
              :counter="3"
              label="Grace Period (days)"
              type="number"
              prepend-icon="mdi-send-clock"
            />
          </v-col>
        </template>
      </v-row>
      <v-card-subtitle :class="textSizeXSmall + ' pt-4'">
        Review the information above. Click
        <span class="accent--text">Add</span> to create a new product variation.
      </v-card-subtitle>
      <v-spacer />
      <v-card-actions class="px-0">
        <BaseActionButton
          icon="mdi-close"
          label="Cancel"
          color="info"
          plain
          @clickedThis="cancel"
        />
        <v-spacer />
        <BaseActionButton
          icon="mdi-plus-circle-outline"
          plain
          :large="!isPhone"
          label="Add"
          @clickedThis="addVariation"
        />
      </v-card-actions>
    </v-card>
    <BaseSnackBar :show="snackbar" :snack="snackText" />
  </v-dialog>
</template>
<script>
import axios from 'axios'
import { appConfig } from '@/store/helpers.js'
import util from '@/mixins/util.js'
import {
  required,
  maxLength,
  minLength,
  minValue
} from 'vuelidate/lib/validators'

export default {
  mixins: [util],
  validations: {
    variation: {
      sku: { required, minLength: minLength(3), maxLength: maxLength(25) },
      description: {
        required,
        minLength: minLength(5),
        maxLength: maxLength(400)
      },
      price: { minValue: minValue(0) },
      grace: { minValue: minValue(0) },
      interval: { minValue: minValue(0) }
    }
  },
  props: {
    show: {
      type: Boolean
    },
    businessid: {
      type: String
    },
    product: {
      type: Object
    }
  },
  data() {
    return {
      initVariation: {
        interval: 0,
        recurrence: 'none',
        use_stock: 'No',
        stock: 0,
        limit_qty: 0,
        grace: 0,
        currency: 'USD',
        price: 0,
        value: '',
        value_details: ''
      },
      variation: {
        interval: 0,
        recurrence: 'none',
        use_stock: 'No',
        stock: 0,
        limit_qty: 0,
        grace: 0,
        currency: 'USD',
        price: 0,
        value: '',
        value_details: ''
      },
      productRecurrence: [],
      currencies: [],
      snackbar: false,
      snackText: '',
      timeout: 1000,
      switchNum: 0
    }
  },
  computed: {
    skuErrors() {
      const errors = []
      if (!this.$v.variation.sku.$dirty) return errors
      !this.$v.variation.sku.required && errors.push('Please enter an SKU.')
      !(this.$v.variation.sku.minLength && this.$v.variation.sku.maxLength) &&
        errors.push('SKU length not in range. Minimum 3 maximum 25.')
      return errors
    },
    descriptionErrors() {
      const errors = []
      if (!this.$v.variation.description.$dirty) return errors
      !this.$v.variation.description.required &&
        errors.push('Please enter a description.')
      !(
        this.$v.variation.description.minLength &&
        this.$v.variation.description.maxLength
      ) &&
        errors.push('Description length not in range. Minimum 5 maximum 400.')
      return errors
    },
    ...appConfig
  },
  beforeMount() {
    this.loadProductRecurrence()
    this.loadCurrencies()
  },
  methods: {
    changeRecurrence() {
      this.variation.interval = this.variation.recurrence == 'none' ? 0 : 1
    },
    reset() {},
    cancel() {
      this.reset()
      this.$emit('cancel')
    },
    loadProductRecurrence() {
      return axios
        .get(this.baseURL + '/product/recurrence', {})
        .then(response => {
          if (response.status == 200) {
            this.productRecurrence = response.data.data
            this.variation.recurrence = this.productRecurrence[0].value
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadCurrencies() {
      return axios
        .get(this.baseURL + '/product/currencies', {})
        .then(response => {
          if (response.status == 200) {
            this.currencies = response.data.data
            this.variation.currency = this.currencies[0].value
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    addVariation() {
      return axios
        .post(this.baseURL + '/product/variation', {
          variation: this.variation,
          product: this.product,
          now: this.now(),
          businessid: this.businessid
        })
        .then(response => {
          if (response.status == 200) {
            this.toast('Product variation added succesfully.')
            this.variation = this.initVariation
            this.$emit('done')
          } else {
            this.toast(
              'Error adding product variation. ' +
                response.data.errors[0].message
            )

            return
          }
        })
    }
  }
}
</script>
